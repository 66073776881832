import { Consts } from '@/types/consts'
import { version } from '../../package.json'

const webServicesPaths = {
  development: 'http://' + location.hostname + ':3000',
  // development: 'http://edge.latestcollection.fashion',
  // development: 'http://beta.latestcollection.fashion',
  // development: 'http://www.latestcollection.fashion',
  production: 'https://' + location.hostname,
}
const webServicesPath = webServicesPaths[process.env.NODE_ENV]

const consts: Consts = {
  defaultURLs: {
    STOCK_LINK: '/reports?type=stock&groups=sku.brand&fields=43&period=TODAY&reportViz=matrix',
    ARTICLE_STATUS_LINK: '/reports?type=articlestatus&groups=sku.brand&fields=1,6,8,12,14,43&period=THISWEEK&reportViz=table',
    TOPX_LINK: '/reports?type=articlestatus&groups=sku.articleCodeSupplier&fields=8&period=LASTWEEK&orderby=g0&sortorder=1&reportViz=cards',
    STOCK_INSIGHT_LINK:
      '/reports?type=stockinsight&groups=sku.brand,sku.articleCodeSupplier,sku.articleDescription,sku.colorFamily&tablekey=sku.size&fields=1,6,8,12,14,43&kpis=10,32,39,36,38,27&period=THISWEEK&reportViz=stockinsight',
  },
  localDateFormat: 'yyyy-MM-dd',
  version: version,
  webServicesPaths,
  dashboardColor: '#625B51',
  webServicesPath,
  erpServices: process.env.NODE_ENV === 'production' ? 'https://api.softwear.nl/myapi' : 'https://alderaan55.softwear.nl/myapi',
  latestCollection: {
    brandLogosUrl: 'https://www.softwear.nl/home/latestcollection/images/size2',
  },
  softwear: {
    fotoManagerUrl: 'http://www.softwear.nl/nl/fotomanager/upload2.php',
    imageRoot: 'https://www.softwear.nl/home',
    stockRoot: 'https://www.softwear.nl/stock',
  },
  tenant: {
    feedId: '',
  },
  groupsIcons: [
    'accessoires',
    'bags',
    'bodywear',
    'bras',
    'dresses',
    'fitness-training',
    'flip-flops',
    'footwear',
    'gloves-',
    'hats',
    'jackets_1',
    'jackets',
    'panties',
    'pants',
    'polo_s',
    'rackets',
    'running',
    'sets',
    'shoes',
    'shorts',
    'singlets',
    'skirts',
    'soccer',
    'socks',
    'sportswear',
    'suits',
    'sweaters',
    'tennis',
    'tights',
    'tops_1',
    'tops',
    't-shirts',
    'unknown',
    'vests',
    'winter-sports',
  ],
  allConfig: {
    favorites: {
      favoriteBrands: {
        role: '',
        level: 'user',
        type: 'autocomplete',
        items: 'brand',
        default: [],
        hidePreferenceInUI: true,
      },
      dashboardOrder: {
        role: '',
        level: 'user',
        type: 'autocomplete',
        items: 'dashboard',
        default: [],
        hidePreferenceInUI: true,
      },
      skuEditorColumns: {
        role: '',
        level: 'user',
        type: 'select',
        default: [
          'barcode',
          'articlecode',
          'articledescription',
          'colorfamily',
          'colorsupplier',
          'colorcodesupplier',
          'size',
          'sizesupplier',
          'buyprice',
          'sellprice',
          'articlegroup',
        ],
        hidePreferenceInUI: true,
      },
      defaultwarehouse: {
        role: '',
        level: 'user',
        type: 'enum',
        items: 'warehouse',
        default: '',
        hidePreferenceInUI: false,
      },
    },
    salesChannels: {
      mySalesChannels: {
        role: 'owner',
        level: 'tenant',
        type: 'autocomplete',
        items: [
          { text: 'wholesale', value: 'wholesale' },
          { text: 'retail-store', value: 'retail-store' },
          { text: 'zalando', value: 'zalando' },
          { text: 'miinto', value: 'miinto' },
          { text: 'wholesale-ecommerce', value: 'wholesale-ecommerce' },
          { text: 'retail-ecommerce', value: 'retail-ecommerce' },
          { text: 'bol', value: 'bol' },
          { text: 'amazon', value: 'amazon' },
        ],
        default: [],
      },
    },
    partnerchannels: {
      latestcollection: {
        role: 'impersonator',
        level: 'tenant',
        type: 'boolean',
        default: true,
      },
      fashioncloud: {
        role: 'owner',
        level: 'tenant',
        type: 'string',
        default: '',
      },
      fashionunited: {
        role: 'owner',
        level: 'tenant',
        type: 'string',
        default: '',
      },
      linolux: {
        role: 'owner',
        level: 'tenant',
        type: 'string',
        default: '',
      },
      triumph_it: {
        role: 'owner',
        level: 'tenant',
        type: 'string',
        default: '',
      },
      euretco: {
        role: 'owner',
        level: 'tenant',
        type: 'string',
        default: '',
      },
      barcodebot: {
        role: 'owner',
        level: 'tenant',
        type: 'boolean',
        default: false,
      },
    },
    sendCloudIntegration: {
      sendCloudPrivateKey: {
        role: 'owner',
        level: 'tenant',
        type: 'string',
        default: '',
      },
      sendCloudPublicKey: {
        role: 'owner',
        level: 'tenant',
        type: 'string',
        default: '',
      },
    },
    zalandointegration: {
      zalandospynltoken: {
        role: 'owner',
        level: 'tenant',
        type: 'string',
        default: '',
      },
      zalandospynlcustomer: {
        role: 'owner',
        level: 'tenant',
        type: 'string',
        default: '',
      },
      defaultzalandowarehouse: {
        role: 'owner',
        level: 'tenant',
        type: 'enum',
        items: 'warehouse',
        default: '',
      },
      zalandodevice: {
        role: 'owner',
        level: 'tenant',
        type: 'string',
        default: '',
      },
      zalandocashier: {
        role: 'owner',
        level: 'tenant',
        type: 'string',
        default: '',
      },
    },
    webshopfulfillment: {
      webshopwarehouse: {
        role: 'owner',
        level: 'tenant',
        type: 'enum',
        items: 'warehouse',
        default: '',
      },
      fulfillmentStrategy: {
        role: 'owner',
        level: 'tenant',
        type: 'enum',
        items: [
          { text: 'None', value: 'none' },
          { text: 'Auto transit', value: 'autoTransit' },
          {
            text: 'Minimize transits, randomize store',
            value: 'minimizeTransitsRandomizeStore',
            disabled: true,
          },
          {
            text: 'Minimize transits, slowest store',
            value: 'minimizeTransitsSlowestStore',
            disabled: true,
          },
          {
            text: 'Minimize transits, fullest store',
            value: 'minimizeTransitsFullestStore',
            disabled: true,
          },
        ],
        default: 'none',
      },
      transitfrom: {
        role: 'owner',
        level: 'tenant',
        type: 'select',
        items: 'warehouse',
        default: [],
      },
      orderfrombrands: {
        role: 'owner',
        level: 'tenant',
        type: 'autocomplete',
        items: 'brand',
        default: [],
      },
      dropshipmentEmailAddress: {
        role: 'owner',
        level: 'tenant',
        type: 'string',
        default: '',
      },
    },
    labelPrinting: {
      labelPrintingStrategy: {
        role: '',
        level: 'tenant',
        type: 'enum',
        items: [
          { text: 'PDF download', value: 'pdfDownload' },
          { text: 'PDF preview', value: 'pdfPreview' },
          { text: 'ZPL', value: 'zplDirect' },
          { text: 'ZPL preview', value: 'zplPreview' },
        ],
        default: 'PDF download',
      },
      pricetagLayoutDefaults: {
        role: '',
        level: 'tenant',
        type: 'combobox',
        items: 'pricetagLayouts',
        default: [],
      },
      printQueues: {
        role: '',
        level: 'tenant',
        type: 'combobox',
        items: '',
        default: [],
      },
    },
    reports: {
      articlestatus: {
        role: 'report',
        level: 'tenant',
        type: 'boolean',
        default: true,
      },
      articlestatus_footprint: {
        role: 'report',
        level: 'tenant',
        type: 'boolean',
        default: false,
      },
      articlestatus_extended: {
        role: 'report',
        level: 'tenant',
        type: 'boolean',
        default: false,
      },
      articlestatus_fifo: {
        role: 'report',
        level: 'tenant',
        type: 'boolean',
        default: false,
      },
      stock: {
        role: 'report',
        level: 'tenant',
        type: 'boolean',
        default: true,
      },
      biGranularity: {
        role: 'owner',
        level: 'tenant',
        type: 'enum',
        items: ['year', 'month'],
        default: 'year',
      },
    },
    vat: {
      high: {
        role: 'owner',
        level: 'tenant',
        type: 'number',
        default: '21',
      },
      low: {
        role: 'owner',
        level: 'tenant',
        type: 'number',
        default: '9',
      },
    },
    products: {
      activeAttributes: {
        role: 'products',
        level: 'tenant',
        type: 'combobox',
        items: 'attribute',
        default: [],
      },
      mapSubSizes: {
        role: 'products-admin',
        level: 'tenant',
        type: 'boolean',
        default: true,
      },
      applyLatestCollectionMapping: {
        role: 'products-admin',
        level: 'tenant',
        type: 'boolean',
        default: true,
      },
      applyTenantMapping: {
        role: 'products-admin',
        level: 'tenant',
        type: 'boolean',
        default: true,
      },
      applyDataProviderMapping: {
        role: 'products-admin',
        level: 'tenant',
        type: 'select',
        items: [{ header: 'SELECT SOME' }, { text: 'euretco' }, { text: 'linolux' }],
        default: [],
      },
      showimagesonproductform: {
        role: 'products',
        level: 'user',
        type: 'boolean',
        default: true,
      },
      defaultcollection: {
        role: 'products',
        level: 'tenant',
        type: 'string',
        default: 'NOS',
      },
      autoCreateSkuForUnknownBarcode: {
        role: 'owner',
        level: 'tenant',
        type: 'boolean',
        default: false,
      },
      sizeranges: {
        role: 'products-admin',
        level: 'tenant',
        type: 'textarea',
        default:
          '\
YS,YM,YL,3XS,XXS,XS,S,M,L,XL,XXL,3XL,4XL\n\
22,24,26,28,30,32,34,36,38,40,42,44,46,48,50,52,54,56\n\
60,65,70,75,80,85,90,95,100,105,110,115,120,125,130,135,140\n\
0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22\n\
XS/S,S/M,M/L,L/XL,XS/S,M/L,XL/XXL\n\
28/28,28/30,28/32,28/34,28/36,28/38,29/28,29/30,29/32,29/34,29/36,29/38,30/28,30/30,30/32,30/34,30/36,30/38,31/28,31/30,31/32,31/34,31/36,31/38,32/28,32/30,32/32,32/34,32/36,32/38,33/28,33/30,33/32,33/34,33/36,33/38,34/28,34/30,34/32,34/34,34/36,34/38,36/28,36/30,36/32,36/34,36/36,36/38,38/28,38/30,38/32,38/34,38/36,38/38,40/28,40/30,40/32,40/34,40/36,40/38\n\
65A,65B,65C,70A,70B,70C\n\
47-48,49-50,51-52,53-54,55-56,57-58,59-60,61-62,63-64,65-66\n\
80,86,92,98,104,110,116,122,128,134,140,146,152,158,164,170,176\n\
25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50\n\
25,25½,26,26½,27,27½,28,28½,29,29½,30,30½,31,31½,32,32½,33,33½,34,34½,35,35½,36,36½,37,37½,38,38½,39,39½,40,40½,41,41½,42,42½,43,43½,44,44½,45,45½,46,46½,47,47½,48,48½,49,49½,50',
      },
    },
    UISettings: {
      language: {
        role: '',
        level: 'tenant',
        type: 'enum',
        items: [
          { text: 'English', value: 'en' },
          { text: 'Nederlands', value: 'nl' },
          { text: 'Deutsch', value: 'de' },
        ],
        default: 'en',
      },
      audioFeedback: {
        role: '',
        level: 'user',
        type: 'boolean',
        default: true,
      },
    },
    synchronizeWith: {
      softwear: {
        role: 'owner',
        level: 'tenant',
        type: 'autocomplete',
        items: [
          { text: 'purchaseOrders', value: 'purchaseorders' },
          { text: 'receivings', value: 'receivings' },
          { text: 'desadv', value: 'desadv' },
          { text: 'invoice', value: 'invoice' },
          { text: 'pos', value: 'pos' },
          { text: 'transit', value: 'transit' },
        ],
        default: [],
      },
    },
    webshop: {
      magentoUrl: { role: 'owner', level: 'tenant', type: 'string', default: '' },
      magentoToken: { role: 'owner', level: 'tenant', type: 'string', default: '' },
    },
    picqerIntegration: {
      picqerApiToken: {
        role: 'owner',
        level: 'tenant',
        type: 'string',
        default: '',
      },
      picqerApiDomain: {
        role: 'owner',
        level: 'tenant',
        type: 'string',
        default: '',
      },
      picqerLatestCollectionWarehouse: {
        role: 'owner',
        level: 'tenant',
        type: 'enum',
        items: 'warehouseId',
        default: '',
        hidePreferenceInUI: false,
      },
      picqerWarehouse: {
        role: 'owner',
        level: 'tenant',
        type: 'enum',
        items: 'picqerWarehouseId',
        focusHook: 'getPicqerWarehouses',
        default: '',
        hidePreferenceInUI: false,
      },
    },
  },
  roles: [
    'account-admin',
    'dashboard-user',
    'inventory-user',
    'logistics-receivings_user',
    'pos-device',
    'products-admin',
    'products-user',
    'products-purchase_admin',
    'products-purchase_user',
    'products-report',
    'sales-user',
    'sales-admin',
    'sw-admin',
    'products-demo',
    'owner',
  ],
}
export default consts
