import { SkuI, MarkedSkuI, ProductI, deepCopy, DATA_PROVIDERS } from '@softwear/latestcollectioncore'
import tools from '@/functions/tools'
import { StartupDBApiI } from '../types/startupDBClient'

/** 
This module is used to store large amounts of data retrieved from the server.
It's not efficient to store all that data in the Vuex store due to all the reactivity overhead.
We only retrieve this data to use some portions of it and make it reactive when we need it.
*/

const globalStore = {
  brandSkus: [] as SkuI[] | MarkedSkuI[],
  brandProducts: [] as ProductI[],
  indexedBrandProducts: {},
  indexedProducts: {},
  latestCollectionAPI: [] as StartupDBApiI[],
  metadata: {},
  transactionsCache: {},
}

function getLatestCollectionAPI(collection: string): StartupDBApiI {
  return globalStore.latestCollectionAPI[collection]
}
function getLatestCollectionArray(collection: string) {
  if (!globalStore.latestCollectionAPI[collection]?.checkPoint?.data) return []
  return Object.values(globalStore.latestCollectionAPI[collection].checkPoint.data) as any[]
}
function setIndexedProducts(products) {
  return (globalStore.indexedProducts = products)
}
function getIndexedProducts() {
  return globalStore.indexedProducts
}
function setIndexedBrandProducts(products) {
  return (globalStore.indexedBrandProducts = products)
}
function getIndexedBrandProducts() {
  return globalStore.indexedBrandProducts
}
function getMetadata() {
  return globalStore.metadata
}
function setMetadata(metadata) {
  return (globalStore.metadata = metadata)
}
function getLatestCollectionObject(collection: string) {
  return globalStore.latestCollectionAPI[collection]?.checkPoint?.data || {}
}
function setLatestCollectionAPI(collection: string, api: StartupDBApiI) {
  globalStore.latestCollectionAPI[collection] = api
}
function resetLatestCollectionAPI(collection: string) {
  delete globalStore.latestCollectionAPI[collection]
}
function getBrandSkus() {
  return globalStore.brandSkus
}
function setBrandSkus(brandSkus: Array<MarkedSkuI>) {
  globalStore.brandSkus = brandSkus
}
function getBrandProducts() {
  return globalStore.brandProducts
}
function setBrandProducts(brandProducts: Array<ProductI>) {
  globalStore.brandProducts = brandProducts
}
function getTransactionsCache() {
  return globalStore.transactionsCache
}
function clearTransactionsCache() {
  Object.keys(globalStore.transactionsCache).forEach((shard) => {
    delete globalStore.transactionsCache[shard]
  })
}
globalThis.globalStore = globalStore

function getItems(items: string, store: any) {
  if (Array.isArray(items)) return items
  switch (items) {
    case 'attribute':
      return Object.keys(store.state.attributes)
    case 'articleGroup':
      return store.state.productGroups
    case 'brand':
      return store.state.tenantBrands
    case 'masterbrands':
      return store.state.brands.map((b) => b.collection)
    case 'mastersuppliers':
      return store.state.brands.filter((b) => b.isSupplier).map((b) => b.collection)
    case 'campaigns':
      return getLatestCollectionArray('campaign').map((campaign) => ({ text: campaign.campaignName, value: campaign.id }))
    case 'collection':
      return deepCopy(store.state.collections).reverse()
    case 'skuCollection':
      return deepCopy(store.state.skuCollections).reverse()
    case 'colorFamily':
      return store.state.colors
    case 'dashboard':
      return store.getters.dashBoardSourceData.map((card) => card.name)
    case 'pricetagLayouts':
      return getLatestCollectionArray('layout')
        .filter((layout) => layout.group === 'pricetag' && layout.active)
        .map((layout) => layout.name)
    case 'salesChannels':
      return store.state.activeConfig.salesChannels.mySalesChannels.value
    case 'size':
      return store.state.sizes
    case 'vatCategory':
      return [
        { text: 'vat-low', value: 'low' },
        { text: 'vat-high', value: 'high' },
        { text: 'vat-zero', value: 'zero' },
      ]
    case 'warehouse':
      return getLatestCollectionArray('warehouse')
        .filter((warehouse) => warehouse.active)
        .map((warehouse) => warehouse.name)
    case 'warehouseId':
      return getLatestCollectionArray('warehouse')
        .filter((warehouse) => warehouse.active)
        .map((warehouse) => ({ text: warehouse.name, value: warehouse.id }))
    case 'picqerWarehouseId':
      return store.state.picqerWarehouses
    case 'dataprovider':
      return DATA_PROVIDERS
    default:
      return tools.getUniqueValues(getLatestCollectionArray('sku').map((e) => e[items])).sort()
  }
}

export default {
  clearTransactionsCache,
  getBrandSkus,
  getBrandProducts,
  getIndexedBrandProducts,
  getIndexedProducts,
  getLatestCollectionArray,
  getLatestCollectionObject,
  getMetadata,
  getTransactionsCache,
  getLatestCollectionAPI,
  setBrandSkus,
  setBrandProducts,
  setIndexedBrandProducts,
  setIndexedProducts,
  setMetadata,
  setLatestCollectionAPI,
  resetLatestCollectionAPI,
  getItems,
}
