import articleStatus from '../functions/articleStatus'
import { swT } from '@/functions/i18n'

export default {
  swT,
  selectorButtons: [
    { name: 'all' },
    { name: 'warehouses' },
    { name: 'brands' },
    { name: 'collections' },
    { name: 'productgroups' },
    { name: 'groups' },
    { name: 'fields' },
    { name: 'none' },
  ],
  stockInsightTableKey: { text: 'sku.size', value: 'sku.size', reportViz: 'table,stockinsight' },
  toggleSelectors: [],
  toggleQuarters: null,
  toggleMonths: [],
  toggleYears: null,
  toggleRelativePeriods: null,
  anyDate: '',
  consistencyChecked: null,
  printDialog: false,
  printBuffer: [],
  valuePickDialog: false,
  valuePickDialogMultiple: false,
  valuePickDialogItems: [],
  commandDialog: '',
  advancedUI: false,
  hideZeroes: false,
  matrixVizibilityToggles: ['showImage'],
  showImage: true,
  showNegativeValues: false,
  showPositiveValues: false,
  calculatingIndicator: false,
  controlPanels: [],
  dates: null,
  fullUI: false,
  foldUI: true,
  manualFilter: '',
  tab: 0,
  modalFromDatePicker: false,
  modalToDatePicker: false,
  noTableFormatting: false,
  rawAggregations: {},
  rawAggregationBuffer: {},
  selectedRelativePeriod: {},
  relativePeriods: ['LAST365', 'LASTMONTH', 'THISMONTH', 'LASTWEEK', 'THISWEEK', 'YESTERDAY', 'TODAY'],
  shardProcessing: 0,
  shardsToProcess: [],
  nrCalculationJobs: 0,
  calculationJob: 0,
  tableSortBy: ['g0'],
  tableSortDesc: [],
  today: '',
  maxRows: 500000,
  selectedFields: [
    articleStatus.QTY_SHELF_STOCK,
    articleStatus.QTY_RECIEVED,
    articleStatus.QTY_SOLD,
    articleStatus.QTY_CONSIGNMENT,
    articleStatus.QTY_CHANGE,
    articleStatus.QTY_TRANSIT,
    articleStatus.QTY_END_SHELF_STOCK,
  ],
  selectedGroups: [],
  allCommands: [
    {
      command: 'SkuEditor',
      selectFields: 'no',
      message: 'skueditor',
      color: 'success',
      icon: 'mdi-table,stockinsight-edit',
      role: 'products-admin',
    },
    {
      command: 'StockTransfer',
      endpoint: 'MatrixEditor',
      selectFields: 'no',
      message: 'stocktransfer',
      color: 'success',
      icon: 'mdi-transfer',
      role: 'products-admin',
    },
    {
      command: 'MinimumStock',
      endpoint: 'MatrixEditor',
      selectFields: 'no',
      message: 'minimum stock',
      color: 'success',
      icon: 'mdi-transfer',
      role: 'products-admin',
    },
    {
      command: 'PriceTags',
      selectFields: 'one',
      message: 'printPricetags',
      color: 'success',
      icon: 'mdi-tag',
      fieldsToPickFrom: [articleStatus.QTY_END_STOCK, articleStatus.QTY_RECIEVED, articleStatus.QTY_TRANSIT],
      role: 'products',
    },
  ],
  fieldChoices: [
    { text: 'beginstockqty', value: articleStatus.QTY_STOCK, fieldType: 0 },
    { text: 'beginstockvalue', value: articleStatus.AMOUNT_STOCK, fieldType: 'currency' },
    { text: 'shelfstockqty', value: articleStatus.QTY_SHELF_STOCK, fieldType: 0 },
    { text: 'shelfstockvalue', value: articleStatus.AMOUNT_SHELF_STOCK, fieldType: 'currency' },
    { text: 'revalue', value: articleStatus.AMOUNT_REVALUATE, fieldType: 'currency' },
    { text: 'receivedqty', value: articleStatus.QTY_RECIEVED, fieldType: 0 },
    { text: 'receivedvalue', value: articleStatus.AMOUNT_RECIEVED, fieldType: 'currency' },
    { text: 'soldqty', value: articleStatus.QTY_SOLD, fieldType: 0 },
    { text: 'soldamountex', value: articleStatus.AMOUNT_SOLD_EXCL, fieldType: 'currency' },
    { text: 'soldcost', value: articleStatus.COSTPRICE_SOLD, fieldType: 'currency' },
    { text: 'changeqty', value: articleStatus.QTY_CHANGE, fieldType: 0 },
    { text: 'changevalue', value: articleStatus.AMOUNT_CHANGE, fieldType: 'currency' },
    { text: 'transitqty', value: articleStatus.QTY_TRANSIT, fieldType: 0 },
    { text: 'transitvalue', value: articleStatus.AMOUNT_TRANSIT, fieldType: 'currency' },
    { text: 'poqty', value: articleStatus.QTY_PO, fieldType: 0 },
    { text: 'povalue', value: articleStatus.AMOUNT_PO, fieldType: 'currency' },
    { text: 'pocompleteqty', value: articleStatus.QTY_PO_COMPLETE, fieldType: 0 },
    { text: 'pocompletevalue', value: articleStatus.AMOUNT_PO_COMPLETE, fieldType: 'currency' },
    { text: 'minstockqty', value: articleStatus.QTY_MINIMUM_STOCK, fieldType: 0 },
    { text: 'minstockvalue', value: articleStatus.AMOUNT_MINIMUM_STOCK, fieldType: 'currency' },
    { text: 'consignmentqty', value: articleStatus.QTY_CONSIGNMENT, fieldType: 0 },
    { text: 'consignmentvalue', value: articleStatus.AMOUNT_CONSIGNMENT, fieldType: 'currency' },
    { text: 'consignmentcost', value: articleStatus.COSTPRICE_CONSIGNMENT, fieldType: 'currency' },
    { text: 'maxreceivedate', value: articleStatus.MAX_RECIEVE_TIMESTAMP, fieldType: 'date' },
    { text: 'endstockqty', value: articleStatus.QTY_END_STOCK, fieldType: 0 },
    { text: 'endstockvalue', value: articleStatus.AMOUNT_END_STOCK, fieldType: 'currency' },
    { text: 'endshelfstockqty', value: articleStatus.QTY_END_SHELF_STOCK, fieldType: 0 },
    {
      text: 'endshelfstockvalue',
      value: articleStatus.AMOUNT_END_SHELF_STOCK,
      fieldType: 'currency',
    },
    { text: 'selloutpercentage', value: articleStatus.SELLOUT_PERCENTAGE, fieldType: 'percentage' },
    { text: 'roi', value: articleStatus.ROI, fieldType: 'currency' },
    { text: 'avgqty', value: articleStatus.QTY_AVG_STOCK, disabled: false, fieldType: 1 },
    { text: 'avgvalue', value: articleStatus.VALUE_AVG_STOCK, disabled: false, fieldType: 'currency' },
    {
      text: 'turnovervelocityqty',
      value: articleStatus.QTY_TURNOVER_VELOCITY,
      fieldType: 1,
    },
    {
      text: 'turnovervelocityamount',
      value: articleStatus.AMOUNT_TURNOVER_VELOCITY,
      fieldType: 1,
    },
    { text: 'profitability', value: articleStatus.PROFITABILITY, fieldType: 'percentage' },
    { text: 'margin', value: articleStatus.MARGIN, fieldType: 'percentage' },
    { text: 'profit', value: articleStatus.PROFIT, fieldType: 'currency' },
    { text: 'return', value: articleStatus.QTY_RETURN, fieldType: 0 },
    { text: 'returnvalue', value: articleStatus.AMOUNT_RETURN, fieldType: 'currency' },
    { text: 'returnpercentage', value: articleStatus.RETURN_PERCENTAGE, fieldType: 'percentage' },
    { text: 'discount', value: articleStatus.AMOUNT_SOLD_DISCOUNT, fieldType: 'currency' },
    { text: 'soldbeforereturnsqty', value: articleStatus.QTY_SOLD_BEFORE_RETURNS, fieldType: 0 },
  ],
  years: [],
  groupChoices: [
    { text: 'sku.articleGroupSupplier', value: 'sku.articleGroupSupplier', reportViz: 'all' },
    { text: 'sku.brand', value: 'sku.brand', reportViz: 'table,stockinsight,matrix,stockinsight' },
    { text: 'sku.collection', value: 'sku.collection', reportViz: 'all' },
    { text: 'sku.collectionSupplier', value: 'sku.collectionSupplier', reportViz: 'table,stockinsight' },
    { text: 'sku.articleGroup', value: 'sku.articleGroup', reportViz: 'all' },
    { text: 'sku.articleCode', value: 'sku.articleCode', reportViz: 'table,stockinsight' },
    { text: 'sku.articleCodeSupplier', value: 'sku.articleCodeSupplier', reportViz: 'all' },
    { text: 'sku.articleDescription', value: 'sku.articleDescription', reportViz: 'table,stockinsight' },
    { text: 'sku.colorCode', value: 'sku.colorCode', reportViz: 'table,stockinsight' },
    { text: 'sku.colorFamily', value: 'sku.colorFamily', reportViz: 'table,stockinsight' },
    { text: 'sku.colorSupplier', value: 'sku.colorSupplier', reportViz: 'table,stockinsight' },
    { text: 'sku.size', value: 'sku.size', reportViz: 'table,stockinsight' },
    { text: 'transaction.ean', value: 'transaction.ean', reportViz: 'table,stockinsight' },
    { text: 'sku.campaigns', value: 'sku.campaigns', reportViz: 'table,stockinsight', fieldType: 'campaignKeys' },
    { text: 'sku.footprint', value: 'sku.FOOTPRINT', reportViz: 'table,stockinsight', showWhenPreference: 'articlestatus_footprint' },
    { text: 'sku.DTBGROUP', value: 'sku.DTBGROUP', reportViz: 'table,stockinsight' },
    { text: 'sku.BTEGROUP', value: 'sku.BTEGROUP', reportViz: 'table,stockinsight' },
    { text: 'Set', value: 'sku.set', reportViz: 'table,stockinsight' },
    { text: 'sku.FEDASGROUP', value: 'sku.FEDASGROUP', reportViz: 'table,stockinsight' },
    { text: 'wh.name', value: 'wh.name', reportViz: 'table,stockinsight,matrix' },
    { text: 'wh.code', value: 'wh.code', reportViz: 'table,stockinsight' },
    { text: 'transaction.docnr', value: 'transaction.docnr', reportViz: 'table,stockinsight,matrix', showWhenPreference: 'articlestatus_extended' },
    { text: 'transaction.vat', value: 'transaction.vat', reportViz: 'table,stockinsight', showWhenPreference: 'articlestatus_extended' },
    { text: 'transaction.type', value: 'transaction.type', reportViz: 'table,stockinsight', fieldType: 'transactionType', showWhenPreference: 'articlestatus_extended' },
    { text: 'transaction.time', value: 'transaction.time', reportViz: 'table,stockinsight', fieldType: 'date', showWhenPreference: 'articlestatus_extended' },
    { text: 'transaction.day', value: 'transaction.day', reportViz: 'table,stockinsight', showWhenPreference: 'articlestatus_extended' },
    { text: 'transaction.week', value: 'transaction.week', reportViz: 'table,stockinsight', showWhenPreference: 'articlestatus_extended' },
    { text: 'transaction.month', value: 'transaction.month', reportViz: 'table,stockinsight', showWhenPreference: 'articlestatus_extended' },
    { text: 'transaction.quarter', value: 'transaction.quarter', reportViz: 'table,stockinsight', showWhenPreference: 'articlestatus_extended' },
    { text: 'transaction.year', value: 'transaction.year', reportViz: 'table,stockinsight', showWhenPreference: 'articlestatus_extended' },
  ],
}
