import { compileExpression } from 'filtrex'
import { format, parse } from 'date-fns'
import { TransactionI, RaGI, AggregatFnI, AggregatorFnI } from '../types/articleStatus'
import articleStatusConfig from './articleStatusConfig'

// fields in Vector
const QTY_TRANSACTION = 0
const QTY_STOCK = 1
const AMOUNT_STOCK = 2
const QTY_SHELF_STOCK = 3
const AMOUNT_SHELF_STOCK = 4
const AMOUNT_REVALUATE = 5
const QTY_RECIEVED = 6
const AMOUNT_RECIEVED = 7
const QTY_SOLD = 8
const AMOUNT_SOLD = 9
const AMOUNT_SOLD_EXCL = 10
const COSTPRICE_SOLD = 11
const QTY_CHANGE = 12
const AMOUNT_CHANGE = 13
const QTY_TRANSIT = 14
const AMOUNT_TRANSIT = 15
const QTY_PO = 16
const AMOUNT_PO = 17
const QTY_PO_COMPLETE = 18
const AMOUNT_PO_COMPLETE = 19
const QTY_MINIMUM_STOCK = 20
const AMOUNT_MINIMUM_STOCK = 21
const QTY_CONSIGNMENT = 22
const AMOUNT_CONSIGNMENT = 23
const COSTPRICE_CONSIGNMENT = 24

// DERIVED FIELDS
const AMOUNT_SOLD_MAX = 25
const AMOUNT_SOLD_DISCOUNT = 26
const MAX_RECIEVE_TIMESTAMP = 27
const STOCK_TIME_PRODUCT = 28
const STOCKAMOUNT_TIME_PRODUCT = 29
const QTY_RETURN = 30
const AMOUNT_RETURN = 31

// Vector length to allocate for aggregation
const VECTOR_LENGTH = 32

// POST AGGREGATION COLUMNS
const SELLOUT_PERCENTAGE = 32
const ROI = 33
const QTY_AVG_STOCK = 34
const VALUE_AVG_STOCK = 35
const QTY_TURNOVER_VELOCITY = 36
const AMOUNT_TURNOVER_VELOCITY = 37
const PROFITABILITY = 38
const MARGIN = 39
const PROFIT = 40
const QTY_END_SHELF_STOCK = 41
const AMOUNT_END_SHELF_STOCK = 42
const QTY_END_STOCK = 43
const AMOUNT_END_STOCK = 44
const RETURN_PERCENTAGE = 45
const QTY_SOLD_BEFORE_RETURNS = 46

const BEGIN_STOCK_VECTOR_START = QTY_STOCK
const BEGIN_STOCK_VECTOR_END = AMOUNT_SHELF_STOCK + 1

const msYear = 24 * 3600 * 365 * 1000

// The propFunction for FiltreX needs some data that I can't pass as parameters. That's why these globals are needed.
// TODO: Functional: Experiment with curried functions
// This is a global variable that holds the joined tables
let globalRelations = {}

// This is a global variable that holds the keys that we can join on
const globalJoinableTables = { sku: 'ean', wh: 'wh', transaction: 'row' }

function propFunction(propertyName: string, getPropertyName: Function, obj: Record<string, any>): any {
  const positionOfDot = propertyName.indexOf('.')
  if (positionOfDot < 0) return getPropertyName(propertyName)
  const table = propertyName.substring(0, positionOfDot)
  const field = propertyName.substring(positionOfDot + 1)
  if (table == 'transaction') return getPropertyName(field)
  return globalRelations[table]?.[obj[globalJoinableTables[table]]]?.[field] || '~ ?'
}

const lower = (s: string) => s.toLowerCase()
const upper = (s: string) => s.toUpperCase()

const filtrexOptions = {
  extraFunctions: { lower, upper },
  customProp: propFunction,
}

// This is how the actual joining of 2 vectors happens
function addVectors(targetVector: Float64Array, otherVector: Float64Array, begin: number, end: number): void {
  for (let i = begin; i < end; i++) targetVector[i] += otherVector[i] || 0
}
let nrAggegationExpressions = 0

// This function takes a transaction and adds it to the aggregations
function addTransactionToAggregations(beginTimestamp: number, endTimestamp: number, aggregateKey: string, rawAggregations: RaGI, transaction: TransactionI, maxRows: number): void {
  let aggregateVector = rawAggregations[aggregateKey]
  if (nrAggegationExpressions >= maxRows) return
  if (!aggregateVector) {
    nrAggegationExpressions++
    aggregateVector = new Float64Array(VECTOR_LENGTH)
    rawAggregations[aggregateKey] = aggregateVector
  }
  const vector = transaction.vector
  if (transaction.type == '1' && transaction.time > aggregateVector[MAX_RECIEVE_TIMESTAMP]) aggregateVector[MAX_RECIEVE_TIMESTAMP] = transaction.time
  if (transaction.time <= beginTimestamp) return addVectors(aggregateVector, vector, BEGIN_STOCK_VECTOR_START, BEGIN_STOCK_VECTOR_END)
  if (transaction.time > endTimestamp) return
  addVectors(aggregateVector, vector, BEGIN_STOCK_VECTOR_END, vector.length)

  // Calculate derived fields

  aggregateVector[QTY_RETURN] += vector[QTY_SOLD] < 0 ? vector[QTY_SOLD] : 0
  aggregateVector[AMOUNT_RETURN] += vector[AMOUNT_SOLD_EXCL] < 0 ? vector[AMOUNT_SOLD_EXCL] : 0

  aggregateVector[STOCK_TIME_PRODUCT] +=
    (vector[QTY_RECIEVED] || 0 + vector[QTY_TRANSIT] || 0 + vector[QTY_CHANGE] || 0 - vector[QTY_SOLD] || 0) * (endTimestamp - transaction.time)
  aggregateVector[STOCKAMOUNT_TIME_PRODUCT] +=
    (vector[AMOUNT_RECIEVED] || 0 + vector[AMOUNT_TRANSIT] || 0 + vector[AMOUNT_CHANGE] || 0 - vector[COSTPRICE_SOLD] || 0) * (endTimestamp - transaction.time)
}

function buildAggregationEvaluator(aggregationExpression: string): Function {
  return compileExpression(aggregationExpression, filtrexOptions)
}

function prepareStockInsightAggKey(aggKey) {
  const brandKPI = aggKey
    .split('\t')
    .slice(0, -2)
    .join('\t')
    .concat(' ΣΣ')
  const articleCodeSupplierKPI = aggKey
    .split('\t')
    .slice(0, -1)
    .join('\t')
    .concat(' Σ')
  return { articleCodeSupplierKPI, brandKPI }
}

/*
 * A factory function that returns a function that can be used to aggregate transactions
 * The factory function is called once for each aggregationExpression
 * @param {Object} options
 *
 * @param {number} options.beginTimestamp - The begin of the time frame UNIX timestamp
 * @param {number} options.endTimestamp - The end of the time frame UNIX timestamp
 *
 * @param {string} options.filterExpression - The FiltreX preprocessed filter expression for: brand, collection, warehouse and articleGroup
 * Example: sku.brand in ("Gabor") and sku.collection not in ("21summer")
 *
 * @param {string} options.aggregationExpression - The aggregation expression that contains the aggregateKey
 * Example: sku.brand+"	"+sku.collection+"	"+sku.articleGroup+"	"+sku.articleCodeSupplier
 *
 * @param {Object} options.rawAggregations - The raw aggregations object where keys are the aggregateKeys and values are the Float64Array vectors
 *
 * @param {number} options.maxRows - The maximum number of rows to aggregate
 * @param {boolean} options.totals - Whether to aggregate totals
 * @param {boolean} options.singleSubtotals - Whether to aggregate single subtotals
 *
 * @returns {Function} - The aggregator function
 */
function aggregator({ beginTimestamp, endTimestamp, filterExpression, aggregationExpression, rawAggregations, maxRows, totals, singleSubtotals, reportViz }: AggregatorFnI) {
  const filterExpressionCache = {}

  let filter = null
  let filterDependencyEvaluator = null
  if (filterExpression) {
    filter = compileExpression(filterExpression, filtrexOptions)
    // Maintain a cache of evaluated filterExpressions
    // The granularity of the cache is determined by the joined tables in the filterExpression
    const filterGranularity = Object.entries(globalJoinableTables)
      .filter((join) => filterExpression.includes(join[0] + '.'))
      .map((join) => join[1])
    filterDependencyEvaluator = compileExpression(filterGranularity.join('+'))
  }
  const aggregationEvaluator = buildAggregationEvaluator(aggregationExpression)
  // Maintain a cache of evaluated aggregationExpressions
  // The granularity of the cache is determined by the joined tables in the aggregationExpression
  const aggregationExpressionCache = {}
  const aggregationGranularity = Object.entries(globalJoinableTables)
    .filter((join) => aggregationExpression.includes(join[0] + '.'))
    .map((join) => join[1])

  let aggregationDependencyExpression = aggregationGranularity.join('+')
  if (!aggregationDependencyExpression) aggregationDependencyExpression = '"N.A."'
  const aggregationDependencyEvaluator = compileExpression(aggregationDependencyExpression)
  return function(transaction: TransactionI): void {
    if (filter) {
      const filterDependency = filterDependencyEvaluator(transaction)
      const filterExpression = filterExpressionCache[filterDependency]
      if (filterExpression == -1) return
      if (!filterExpression) {
        filterExpressionCache[filterDependency] = filter(transaction) ? 1 : -1
        if (filterExpressionCache[filterDependency] == -1) return
      }
    }
    const aggregationDependency = aggregationDependencyEvaluator(transaction)
    let aggregateKey = aggregationExpressionCache[aggregationDependency]
    if (!aggregateKey) {
      aggregateKey = aggregationEvaluator(transaction)
      aggregationExpressionCache[aggregationDependency] = aggregateKey
    }
    // Prepare joined values for derived fields
    if (transaction.type == '2') {
      const sku = globalRelations['sku'][transaction.ean]
      const vector = transaction.vector
      vector[AMOUNT_SOLD_MAX] = (sku?.price || 0) * vector[QTY_SOLD]
      vector[AMOUNT_SOLD_DISCOUNT] = vector[AMOUNT_SOLD_MAX] - vector[AMOUNT_SOLD]
    }

    if (totals) addTransactionToAggregations(beginTimestamp, endTimestamp, '** TOTAL **', rawAggregations, transaction, maxRows)

    // Single subtotals are subtotals for first grouper level
    // Example: for 4 groupers we have rows like
    // g0 g1 g2 g3 <- normal row with no subtotal
    // g0          <- subtotal for g0
    if (singleSubtotals == true) {
      addTransactionToAggregations(beginTimestamp, endTimestamp, `${aggregateKey.substr(0, aggregateKey.indexOf('\t'))} Σ`, rawAggregations, transaction, maxRows)
    }

    if (reportViz == 'stockinsight') {
      const { articleCodeSupplierKPI } = prepareStockInsightAggKey(aggregateKey)
      addTransactionToAggregations(beginTimestamp, endTimestamp, articleCodeSupplierKPI, rawAggregations, transaction, maxRows)
      addTransactionToAggregations(beginTimestamp, endTimestamp, aggregateKey, rawAggregations, transaction, maxRows)
    } else {
      addTransactionToAggregations(beginTimestamp, endTimestamp, aggregateKey, rawAggregations, transaction, maxRows)
    }
  }
}

function aggregate({
  beginTimestamp,
  endTimestamp,
  filterExpression,
  aggregationExpression,
  transactions,
  beginStock,
  relations,
  rawAggregations,
  maxRows,
  totals,
  singleSubtotals,
  reportViz,
}: AggregatFnI) {
  nrAggegationExpressions = 0
  globalRelations = relations

  const compiledAggregator = aggregator({
    beginTimestamp,
    endTimestamp,
    filterExpression,
    aggregationExpression,
    rawAggregations,
    maxRows,
    totals,
    singleSubtotals,
    reportViz,
  })
  if (!beginStock) transactions.filter((t: TransactionI) => t.type != '0').forEach(compiledAggregator)
  else transactions.forEach(compiledAggregator)

  return rawAggregations
}
function whichShardsToProcess(dateRange: any, granularity: 'year' | 'month'): any[] {
  const padl = function(s: number) {
    if (s > 9) return '' + s
    return '0' + s
  }
  const result = []
  if (granularity == 'year') for (let year = dateRange.fromYear; year <= dateRange.toYear; year++) result.push(year)
  else
    for (let year = dateRange.fromYear; year <= dateRange.toYear; year++) {
      if (year == dateRange.fromYear && year < dateRange.toYear) for (let month = dateRange.fromMonth; month <= 12; month++) result.push('' + year + '' + padl(month))
      if (year == dateRange.fromYear && year == dateRange.toYear)
        for (let month = dateRange.fromMonth; month <= dateRange.toMonth; month++) result.push('' + year + '' + padl(month))
      if (year != dateRange.fromYear && year != dateRange.toYear) for (let month = 1; month <= 12; month++) result.push('' + year + '' + padl(month))
      if (year != dateRange.fromYear && year == dateRange.toYear) for (let month = 1; month <= dateRange.toMonth; month++) result.push('' + year + '' + padl(month))
    }
  return result
}
/**
 *
 * @param {*} v: a vector
 * @param {*} timeFrame: nr miliseconds in timeframe
 *
 * Calculate all derived columns on a given vector.
 */
function postAgg(v: any, timeFrame: number): void {
  v[QTY_END_STOCK] = v[QTY_STOCK] + v[QTY_RECIEVED] - v[QTY_SOLD] + v[QTY_CHANGE] + v[QTY_TRANSIT]
  v[AMOUNT_END_STOCK] = v[AMOUNT_STOCK] + v[AMOUNT_RECIEVED] - v[COSTPRICE_SOLD] + v[AMOUNT_CHANGE] + v[AMOUNT_TRANSIT] + v[AMOUNT_REVALUATE]
  v[QTY_END_SHELF_STOCK] = v[QTY_SHELF_STOCK] + v[QTY_RECIEVED] - v[QTY_SOLD] - v[QTY_CONSIGNMENT] + v[QTY_CHANGE] + v[QTY_TRANSIT]
  v[AMOUNT_END_SHELF_STOCK] = v[AMOUNT_SHELF_STOCK] + v[AMOUNT_RECIEVED] - v[COSTPRICE_SOLD] + v[COSTPRICE_CONSIGNMENT] + v[AMOUNT_CHANGE] + v[AMOUNT_TRANSIT]
  v[SELLOUT_PERCENTAGE] = (v[QTY_SOLD] / (v[QTY_SOLD] + v[QTY_END_STOCK])) * 100
  v[ROI] = v[AMOUNT_SOLD_EXCL] - v[AMOUNT_RECIEVED] + v[AMOUNT_TRANSIT]
  v[QTY_AVG_STOCK] = (v[QTY_STOCK] * timeFrame + v[STOCK_TIME_PRODUCT]) / timeFrame
  v[VALUE_AVG_STOCK] = (v[AMOUNT_STOCK] * timeFrame + v[STOCKAMOUNT_TIME_PRODUCT]) / timeFrame
  v[QTY_TURNOVER_VELOCITY] = ((v[QTY_SOLD] / v[QTY_AVG_STOCK]) * msYear) / timeFrame
  v[AMOUNT_TURNOVER_VELOCITY] = ((v[COSTPRICE_SOLD] / v[VALUE_AVG_STOCK]) * msYear) / timeFrame
  v[PROFIT] = v[AMOUNT_SOLD_EXCL] - v[COSTPRICE_SOLD]
  v[PROFITABILITY] = (v[PROFIT] / v[COSTPRICE_SOLD]) * v[QTY_TURNOVER_VELOCITY] * 100
  v[MARGIN] = (v[PROFIT] / v[AMOUNT_SOLD_EXCL]) * 100
  v[RETURN_PERCENTAGE] = (v[QTY_RETURN] / v[QTY_RECIEVED]) * 100
  v[QTY_SOLD_BEFORE_RETURNS] = v[QTY_SOLD] + Math.abs(v[QTY_RETURN])
}

function isGroupColumn(column: string) {
  if (!column) return false
  return column[0] == 'g'
}

const getDateRangeFromPicker = function(inputDates: string[]) {
  if (!Array.isArray(inputDates))
    return {
      beginTimeStamp: 0,
      endTimeStamp: 0,
      timeFrame: 0,
      fromYear: 0,
      toYear: 0,
      fromMonth: 0,
      toMonth: 0,
      hrYear: '',
      hrQuarter: '',
      hrMonth: '',
      hrWeek: '',
      hrDay: '',
    }
  const dates = inputDates.slice()
  dates.sort((a, b) => {
    return ('' + a).localeCompare(b)
  })
  // @ts-ignore
  const fromDate = parse(dates[0], 'yyyy-MM-dd', new Date())
  fromDate.setSeconds(fromDate.getSeconds())

  // @ts-ignore
  const toDate = parse(dates[1], 'yyyy-MM-dd', new Date())
  const beginTimeStamp = fromDate.getTime()
  const endTimeStamp = toDate.getTime() + 24 * 3600 * 1000
  const timeFrame = endTimeStamp - beginTimeStamp
  return {
    beginTimeStamp,
    endTimeStamp,
    timeFrame,
    fromYear: parseInt(format(fromDate, 'yyyy')),
    toYear: parseInt(format(toDate, 'yyyy')),
    fromMonth: parseInt(format(fromDate, 'MM')),
    toMonth: parseInt(format(toDate, 'MM')),
    hrYear: format(fromDate, 'yyyy'),
    hrQuarter: format(fromDate, 'yyyy-QQQ'),
    hrMonth: format(fromDate, 'yyyy-MM'),
    hrWeek: format(fromDate, 'RRRR-II'),
    hrDay: format(fromDate, 'yyyy-MM-dd'),
  }
}

function buildTableUIData(aggregations, selectedFields, headers, dates, appFilter = '') {
  const intermediateAggregations = []
  const makeFootprintCalculations = headers[0]?.field == 'sku.brand' && headers[1]?.field == 'sku.FOOTPRINT'
  const filterOptions = {}
  const groupers = {}
  const timeFrame = getDateRangeFromPicker(dates).timeFrame
  Object.entries(aggregations)
    // appFilter filters only groupers
    .filter((oneAggregatedRow) => !appFilter || oneAggregatedRow[0].toLowerCase().includes(appFilter))
    .forEach((oneAggregatedRow) => {
      const key = oneAggregatedRow[0]
      const result = [key, null]

      const vector = [].slice.call(oneAggregatedRow[1]) // vector is now a normal Array instead of a Float64Array so can be extended with additional fields
      postAgg(vector, timeFrame)

      const row = {} as any

      key.split('\t').forEach((item, index) => {
        const grouperValue = item == 'undefined' ? '' : item
        const grouperIndex = 'g' + index

        row[grouperIndex] = grouperValue
        // Add the item to the filterOptions if grouperValue is not already there
        if (!groupers[grouperIndex]) groupers[grouperIndex] = {}
        if (!groupers[grouperIndex][grouperValue]) groupers[grouperIndex][grouperValue] = 0
        // if there is already an entry, do nothing
      })

      selectedFields.forEach((field) => {
        row['v' + field] = vector[field]
        if (makeFootprintCalculations) row['v' + field + 1000] = vector[field] / parseFloat(row.g1)
      })
      if (key == '** TOTAL **') row.class = 'font-weight-black'
      if (key.endsWith('Σ')) row.class = 'subtotal'
      result[2] = row
      intermediateAggregations.push(result)
    })

  // filterOptions is a map of all possible values for each group column, translates to dropdown items in the grouper UI filter
  // For groupers `brand` and `collections` the form is:
  // { g0: { poools: 0, adidas: 0, 'value2': 0, ... }, g1: { summer20: 0, winter22: 0, 'value2': 0, ... }, ...
  // we are not interested in the '0's but the keys, we use them for lookups
  Object.keys(groupers).forEach((key) => {
    filterOptions[key] = Object.keys(groupers[key])
  })

  return { intermediateAggregations, filterOptions }
}

function compileFiltrexExpression(groupersFilters) {
  const filter = Object.entries(groupersFilters)
    // Filter out empty arrays and null values
    // filterValue looks like an array, its a `vue` object (an observer with an array constructor)
    // Check if the value is an object (array like) with 0 length OR the value is null, then inverse the result to satisfy the filter api
    // @ts-ignore
    .filter(([, filterValue]) => !((typeof filterValue === 'object' && filterValue.length === 0) || filterValue === null))
    // Build the filter string for the FiltreX compiler: (lower(g0) ~= "foo") and (g1 ~= "bar")
    .map(([key, filterValue]) => {
      let value
      if (Array.isArray(filterValue) && filterValue.length) value = `(${key} in (${filterValue.map((v) => `"${v}"`).join(',')}))`
      if (typeof filterValue === 'string') value = `(lower(${key}) ~= "${filterValue}")`

      return value
    })
    .join(' and ')

  // The filter is an empty string only if the user has cleared all filters, so show everything
  // Otherwise compile the filter expression
  return filter === '' ? undefined : compileExpression(filter, filtrexOptions)
}

// Small helper function to intercept 'coded' values that cannot be parsed into a float
function getExactValue(value, filterSymbols) {
  // These cases should be caught in the filter
  if (filterSymbols.includes(value.substring(0, 1))) return value
  return parseFloat(value)
}

// Function testing if a row passes the filter
// Not returning anything means the row passes the filter
// Can still return a bool
// It is weird for efficiency reasons
const filterSymbols = ['!', '<', '>', '-', '>=', '<=']

function headerColumnFilter(row, key: string, searchString: string): void | boolean {
  const value = row[2][key]

  const isADate = ['v27'].includes(key)
  const searchDate = searchString

  const bounds = searchString.split('..')
  const isARange = searchString.includes('..')
  const exactValue = getExactValue(searchString, filterSymbols)
  const isExactDate = isADate && !isARange

  // A search without a range
  if (!isARange) {
    // All exceptions should match the filterSymbols
    if (typeof exactValue == 'string') {
      if (exactValue == '-') return value < 0

      // For any element of the filterSymbols and any element followed by "-" return true.
      // We do not want to hide the data while the user types '>=-'
      if (filterSymbols.some((elem) => elem === exactValue || `${elem}-` === exactValue)) return true

      // Deal with the symbols
      if (exactValue.startsWith('!')) return value != parseFloat(exactValue.substring(1))

      if (exactValue.startsWith('<=')) return value <= parseFloat(exactValue.substring(2))
      if (exactValue.startsWith('>=')) return value >= parseFloat(exactValue.substring(2))

      if (exactValue.startsWith('<')) return value < parseFloat(exactValue.substring(1))
      if (exactValue.startsWith('>')) return value > parseFloat(exactValue.substring(1))
    }
    if (value != exactValue) return false
  }

  // A search with a range
  // Determine the bounds
  // --------------------
  let start, end

  if (isExactDate) {
    // starts at filter date, ends one day later
    start = parse(searchDate, 'yyyy-MM-dd', new Date()).getTime()
    end = start + 24 * 3600 * 1000
    if (value >= start && value <= end) return true
  }

  if (isADate && isARange)
    // it is a range, so we parse the bounds. Add a day to the end date to make it inclusive
    [start, end] = [parse(bounds[0], 'yyyy-MM-dd', new Date()).getTime(), parse(bounds[1], 'yyyy-MM-dd', new Date()).getTime() + 24 * 3600]

  if (!isADate) [start, end] = [parseFloat(bounds[0]), parseFloat(bounds[1])]

  // Filter out rows that don't match the filter
  // -------------------------------------------
  if (searchString.startsWith('..')) return value <= end
  if (searchString.endsWith('..')) return value >= start

  if (isARange && (value < start || value > end)) return false
}

// This function has to be efficient, it is called for every row
// We iterate once and filter groupers first, then columns, then custom filter
// The order is not random, it is based on the fact that we want to filter out as many rows as possible as soon as possible

// For groupers we use the FILTREX expression to filter, groupFiltrexExpression is a filter cb function

// For columns we want to react on the first flag that does not pass the filter
// We look for the first 'false' - meaning the row does not pass the filter
// Then we exit the loop but `some()` will return true so we negate that

// We can take advantage of a custom filtering calback function
// We have a pretty random way of filtering, for now we check whether there is a 'Σ' symbol in the key
function filterUIDataTableRows(rows, groupFiltrexExpression, columnsFilter: string[], customFilter: (row: any, showSubtotals: boolean) => boolean, showSubtotals: boolean) {
  const columnsFilterArray = Object.entries(columnsFilter)
  return rows.filter((oneAggregatedRow) => {
    return (
      (groupFiltrexExpression ? groupFiltrexExpression(oneAggregatedRow[2]) : true) &&
      !columnsFilterArray.some(([key, searchString]) => headerColumnFilter(oneAggregatedRow, key, searchString) == false) &&
      customFilter(oneAggregatedRow, showSubtotals)
    )
  })
}

function buildGroupersAndColumnsFilter(headerFilters: string[]) {
  const columnsFilter = {}
  const groupersFilter = {}

  for (const [key, value] of Object.entries(headerFilters)) {
    if (value === '' || value === null) continue

    if (key.startsWith('g')) {
      Array.isArray(value) ? (groupersFilter[key] = value) : (groupersFilter[key] = value.toLowerCase())
      continue
    }

    columnsFilter[key] = value.toLowerCase()
  }
  return { columnsFilter, groupersFilter }
}

function getTimeGrouper(selectedGroups) {
  const groupers = selectedGroups.map((g) => g.value)
  if (groupers.includes('transaction.year')) return 'year'
  if (groupers.includes('transaction.month')) return 'month'
  if (groupers.includes('transaction.quarter')) return 'quarter'
  if (groupers.includes('transaction.week')) return 'week'
  if (groupers.includes('transaction.day')) return 'day'
  return ''
}

function prepareGroupsForVisualization(reportViz, groups) {
  if (reportViz == 'matrix')
    return groups
      .filter((g) => !['sku.articleCodeSupplier', 'sku.articleDescription', 'transaction.ean'].includes(g))
      .concat(['sku.articleCodeSupplier', 'sku.articleDescription', 'transaction.ean'])
  if (reportViz == 'cards')
    return ['sku.articleCodeSupplier', 'sku.articleDescription', 'sku.brand'].concat(
      groups.filter((g) => !['sku.articleCodeSupplier', 'sku.articleDescription', 'sku.brand'].includes(g))
    )
  return groups
}

function runQuery(timeframe, rawAggregations, beginStock, transactions, reportViz, selectedGroupsValues, selectedGroups, skus, warehouses, singleSubtotals, computedFilter) {
  const effectiveGroups = prepareGroupsForVisualization(reportViz, selectedGroupsValues)
  const timeGrouper = getTimeGrouper(selectedGroups)
  const aggregationExpression = effectiveGroups
    .map((g) => {
      if (g == 'transaction.year') return '"' + timeframe.hrYear + '"'
      if (g == 'transaction.quarter') return '"' + timeframe.hrQuarter + '"'
      if (g == 'transaction.month') return '"' + timeframe.hrMonth + '"'
      if (g == 'transaction.week') return '"' + timeframe.hrWeek + '"'
      if (g == 'transaction.day') return '"' + timeframe.hrDay + '"'
      return g
    })
    .join('+"\t"+')
  try {
    aggregate({
      beginTimestamp: timeframe.beginTimeStamp,
      endTimestamp: timeframe.endTimeStamp,
      filterExpression: computedFilter,
      aggregationExpression: aggregationExpression,
      transactions: transactions,
      beginStock: beginStock,
      relations: {
        sku: skus,
        wh: warehouses,
      },
      rawAggregations: rawAggregations,
      maxRows: articleStatusConfig.maxRows,
      totals: timeGrouper == '',
      singleSubtotals,
      reportViz,
    })
  } catch (e) {
    return rawAggregations // most probably an end-user typo in the manualFilter
  }
  return rawAggregations
}

export default {
  aggregate,
  buildGroupersAndColumnsFilter,
  buildTableUIData,
  compileFiltrexExpression,
  filterUIDataTableRows,
  getDateRangeFromPicker,
  isGroupColumn,
  postAgg,
  runQuery,
  whichShardsToProcess,
  AMOUNT_CHANGE,
  AMOUNT_CONSIGNMENT,
  AMOUNT_END_SHELF_STOCK,
  AMOUNT_END_STOCK,
  AMOUNT_MINIMUM_STOCK,
  AMOUNT_PO,
  AMOUNT_PO_COMPLETE,
  AMOUNT_RECIEVED,
  AMOUNT_RETURN,
  AMOUNT_REVALUATE,
  AMOUNT_SHELF_STOCK,
  AMOUNT_SOLD,
  AMOUNT_SOLD_DISCOUNT,
  AMOUNT_SOLD_EXCL,
  AMOUNT_SOLD_MAX,
  AMOUNT_STOCK,
  AMOUNT_TRANSIT,
  AMOUNT_TURNOVER_VELOCITY,
  COSTPRICE_CONSIGNMENT,
  COSTPRICE_SOLD,
  MARGIN,
  MAX_RECIEVE_TIMESTAMP,
  PROFIT,
  PROFITABILITY,
  QTY_AVG_STOCK,
  QTY_CHANGE,
  QTY_CONSIGNMENT,
  QTY_END_SHELF_STOCK,
  QTY_END_STOCK,
  QTY_MINIMUM_STOCK,
  QTY_PO,
  QTY_PO_COMPLETE,
  QTY_RECIEVED,
  QTY_RETURN,
  QTY_SHELF_STOCK,
  QTY_SOLD,
  QTY_SOLD_BEFORE_RETURNS,
  QTY_STOCK,
  QTY_TRANSACTION,
  QTY_TRANSIT,
  QTY_TURNOVER_VELOCITY,
  RETURN_PERCENTAGE,
  ROI,
  SELLOUT_PERCENTAGE,
  STOCKAMOUNT_TIME_PRODUCT,
  STOCK_TIME_PRODUCT,
  VALUE_AVG_STOCK,
}
