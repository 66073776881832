// initial state
const state = () => ({
  brandFilter: null,
  brandFilterEquals: true,
  collectionFilter: null,
  collectionFilterEquals: true,
  dateName: null,
  filterDateRange: null,
  hideFullGrouper: true,
  itemsPerPage: 20,
  productGroupFilter: null,
  productGroupFilterEquals: true,
  selectedFields: null,
  selectedGroups: null,
  selectedKPIs: null,
  warehouseFilter: null,
  warehouseFilterEquals: true,
  withSubtotals: null,
  showSubtotals: true,
  defaultQuery: {
    type: 'articlestatus',
    groups: 'sku.brand',
    filter: '',
    fields: '1,6,8,12,14,43',
    period: 'THISWEEK',
    reportViz: 'table',
  },
})

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
