<template>
  <span :class="showRoles ? 'caption d-flex' : 'd-flex'" @click="callback()">
    <v-icon>{{ item.icon }}</v-icon>
    <span class="ml-2 mr-1 text-truncate">
      {{ swT(item.name) }}
      <v-chip v-if="item.unreadCount > 0" small>{{ item.unreadCount }}</v-chip>
    </span>
    <span v-if="item.beta" class="pr-4 warning--text ml-auto">beta</span>
    <span v-else class="pr-4 ml-auto">{{ item.shortcut || '' }}</span>
    <span v-if="showRoles" class="caption pa-0 ma-0 green--text">
      <br />
      {{ item.role }}
    </span>
  </span>
</template>

<script>
import { swT } from '@/functions/i18n'
import { eventBus } from '../main'

export default {
  props: ['item', 'showRoles'],
  data() {
    return {
      swT,
    }
  },
  methods: {
    callback() {
      if (['/receivings', '/purchaseorders'].includes(this.item.path)) {
        eventBus.$emit('resetGenericView')
      }
      if (this.item.path == '/skueditor') {
        eventBus.$emit('resetSkuEditor')
      }
    },
  },
}
</script>
