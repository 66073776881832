import consts from '../store/consts'
import { format } from 'date-fns'

const dateToday = new Date()
const dateYearAgo = new Date(new Date().setFullYear(new Date().getFullYear() - 1))
const dateWeekAgo = new Date(dateToday).setDate(dateToday.getDate() - 6)
const dateMonthAgo = new Date(dateToday).setDate(dateToday.getDate() - 29)
const today = format(dateToday, 'yyyy-MM-dd')
const todayYearAgo = format(dateYearAgo, 'yyyy-MM-dd')
const todayWeekAgo = format(dateWeekAgo, 'yyyy-MM-dd')
const todayMonthAgo = format(dateMonthAgo, 'yyyy-MM-dd')

const sourceData = [
  {
    name: 'nrOpenPOS',
    title: 'open_POS',
    objectData: 'noData',
    type: 'number',
    description: '# Open POS',
    icon: 'mdi-store',
    role: ['sw-admin'],
    color: 'blue',
    updateFunc: 'activePOS',
    tenantID: ['master'],
  },
  {
    name: 'nrClosedPOS',
    title: 'closed_POS',
    objectData: 'noData',
    type: 'number',
    description: '# closed cash registers',
    icon: 'mdi-store-off',
    role: ['sw-admin'],
    color: 'blue',
    updateFunc: 'activePOS',
    tenantID: [],
  },
  {
    name: 'M2FEvents',
    title: 'M2F_events',
    objectData: 'noData',
    type: 'status',
    description: '# M2F events',
    icon: 'mdi-alert-circle-outline',
    role: ['sw-admin'],
    color: 100,
    updateFunc: 'getM2FEvents',
    tenantID: [],
    url: '',
  },
  {
    name: 'LCconnections',
    title: 'LC_connections',
    objectData: 'noData',
    type: 'progress',
    description: 'LC connections',
    icon: 'mdi-store-off',
    role: ['sw-admin'],
    color: consts.dashboardColor,
    updateFunc: 'getLATConnections',
    tenantID: [],
  },
  {
    name: 'openOverActive',
    title: 'open_cashregisters',
    objectData: 'noData',
    type: 'progress',
    description: 'Opened cash registers / all active devices',
    icon: 'mdi-store-off',
    role: ['sw-admin'],
    color: consts.dashboardColor,
    updateFunc: 'getSpynlMetrics',
    tenantID: [],
  },
  {
    name: 'openOverClosed',
    title: 'closed_cashregisters',
    objectData: 'noData',
    type: 'progress',
    description: 'Closed cash registers / opened cashregisters',
    icon: 'mdi-store-off',
    role: ['sw-admin'],
    color: consts.dashboardColor,
    updateFunc: 'activePOS',
    tenantID: [],
  },
  {
    name: 'EOSOverActive',
    title: 'EOS_over_active',
    objectData: 'noData',
    type: 'progress',
    description: 'Devices using EOS / all active devices',
    icon: 'mdi-store-off',
    role: ['sw-admin'],
    color: consts.dashboardColor,
    updateFunc: 'getSpynlMetrics',
    tenantID: [],
  },
  {
    name: 'USBOverActive',
    title: 'USB_over_active',
    objectData: 'noData',
    type: 'progress',
    description: 'Devices using Web USB / all active devices',
    icon: 'mdi-store-off',
    role: ['sw-admin'],
    color: consts.dashboardColor,
    updateFunc: 'getSpynlMetrics',
    tenantID: [],
  },
  {
    name: 'spynlSalesPerWarehouse',
    title: 'sales_per_warehouse',
    objectData: [],
    type: 'dates',
    description: 'Sales per warehouse',
    icon: 'mdi-cart-percent',
    role: [],
    color: 'orange',
    updateFunc: 'getSpynlSalesPerWarehouse',
    tenantID: [],
    config: {
      dates: [today, todayYearAgo], // Dates that have test data: '2023-05-31', '2023-04-30'
      threshold: 0,
    },
  },
  {
    name: 'spynlSalesPerPeriod',
    title: 'sales_last_12m',
    objectData: [],
    type: 'sparkline',
    description: 'Sales per period',
    icon: 'mdi-chart-multiple',
    role: [],
    color: 'orange',
    updateFunc: 'getSpynlSalesPerPeriod',
    tenantID: [],
    config: {
      dates: [today, todayYearAgo], // Dates that have test data: '2024-12-31', '2024-01-01'
      groupBy: 'month',
      currency: true,
      totalTime: 'year',
      XaxisLabels: [],
    },
  },
  {
    name: 'salesThisMonth',
    title: 'sales_last_30d',
    objectData: [],
    type: 'sparkline',
    description: 'Sales per period',
    icon: 'mdi-chart-line',
    role: [],
    color: 'orange',
    updateFunc: 'getSpynlSalesPerPeriod',
    tenantID: [],
    config: {
      dates: [today, todayMonthAgo],
      groupBy: 'day',
      currency: true,
      totalTime: 'month',
      XaxisLabels: [],
    },
  },
  {
    name: 'salesThisWeek',
    title: 'sales_last_7d',
    objectData: [],
    type: 'sparkline',
    description: 'Sales per period',
    icon: 'mdi-chart-line',
    role: [],
    color: 'orange',
    updateFunc: 'getSpynlSalesPerPeriod',
    tenantID: [],
    config: {
      dates: [today, todayWeekAgo],
      groupBy: 'day',
      currency: true,
      totalTime: 'week',
      XaxisLabels: [],
    },
  },
  {
    name: 'todaysTurnover',
    title: 'today_turnover',
    objectData: 'No data',
    type: 'currency',
    description: 'today_turnover_description',
    icon: 'mdi-cash-multiple',
    role: [],
    color: 'blue',
    updateFunc: 'getSpynlSalesSummary',
    tenantID: [],
    config: {
      dates: [today, today], // Dates that have test data: '2023-05-30', '2023-05-30'
    },
  },
  {
    name: 'todaysTransactions',
    title: 'today_transactions',
    objectData: 'No data',
    type: 'number',
    description: 'today_transactions_description',
    icon: 'mdi-cash-register',
    role: [],
    color: 'blue',
    updateFunc: 'getSpynlSalesSummary',
    tenantID: [],
    config: {
      dates: [today, today], // Dates that have test data: '2023-05-30', '2023-05-30'
    },
  },
  {
    name: 'todaysAvgQty',
    title: 'today_avg_qty',
    objectData: 'No data',
    type: 'number',
    description: 'today_avg_qty_description',
    icon: 'mdi-receipt-text',
    role: [],
    color: 'blue',
    updateFunc: 'getSpynlSalesSummary',
    tenantID: [],
    config: {
      dates: [today, today], // Dates that have test data: '2023-05-30', '2023-05-30'
    },
  },
  {
    name: 'NPqtyQueue',
    title: 'RUN queue',
    objectData: 'noData',
    type: 'number',
    description: 'Run queue',
    icon: 'mdi-checkbox-multiple-marked',
    role: [],
    color: 'blue',
    updateFunc: 'getNPQueue',
    tenantID: ['91389', 'master'],
  },
  {
    name: 'NPqtyFailed',
    title: 'Failed queue',
    objectData: 'noData',
    type: 'number',
    description: 'Run queue',
    icon: 'mdi-close-box-multiple',
    role: [],
    color: 'blue',
    updateFunc: 'getNPPromese',
    tenantID: ['91389', 'master'],
  },
  {
    name: 'NPDeltastocks',
    title: 'Delta Stocks',
    objectData: 'noData',
    type: 'number',
    description: 'Promese',
    icon: 'mdi-delta',
    role: [],
    color: 'indigo',
    updateFunc: 'getNPPromese',
    tenantID: ['91389', 'master'],
  },
  {
    name: 'NPFullstock',
    title: 'Full Stock',
    objectData: 'noData',
    type: 'number',
    description: 'Promese',
    icon: 'mdi-tray-full',
    role: [],
    color: 'indigo',
    updateFunc: 'getNPPromese',
    tenantID: ['91389', 'master'],
  },
  {
    name: 'NPReturns',
    title: 'Returns',
    objectData: 'noData',
    type: 'number',
    description: 'Promese',
    icon: 'mdi-clipboard-arrow-left',
    role: [],
    color: 'indigo',
    updateFunc: 'getNPPromese',
    tenantID: ['91389', 'master'],
  },
  {
    name: 'NPShipments',
    title: 'Shipments',
    objectData: 'noData',
    type: 'number',
    description: 'Promese',
    icon: 'mdi-truck',
    role: [],
    color: 'indigo',
    updateFunc: 'getNPPromese',
    tenantID: ['91389', 'master'],
  },
  {
    name: 'NPWrSyncStatus',
    title: 'WrSync',
    objectData: 'noData',
    type: 'status',
    description: 'Services',
    icon: 'mdi-autorenew',
    role: [],
    color: '',
    updateFunc: 'getNPWrSync',
    tenantID: ['91389', 'master'],
    url: '',
  },
  {
    name: 'NPBijenkorfINV',
    title: 'Bijenkorf INV',
    objectData: 'noData',
    type: 'number',
    description: 'EDI',
    icon: 'mdi-file-document-multiple',
    role: [],
    color: '#CD9575',
    updateFunc: 'getNPEDI',
    tenantID: ['91389', 'master'],
  },
  {
    name: 'NPBijenkorfSlS',
    title: 'Bijenkorf SLS',
    objectData: 'noData',
    type: 'number',
    description: 'EDI',
    icon: 'mdi-file-document-multiple',
    role: [],
    color: '#CD9575',
    updateFunc: 'getNPEDI',
    tenantID: ['91389', 'master'],
  },
  {
    name: 'NPBijenkorf',
    title: 'Bijenkorf ---',
    objectData: 'noData',
    type: 'number',
    description: 'EDI',
    icon: 'mdi-file-document-multiple',
    role: [],
    color: '#CD9575',
    updateFunc: 'getNPEDI',
    tenantID: ['91389', 'master'],
  },
  {
    name: 'NPOtherINV',
    title: 'OtherINV',
    objectData: 'noData',
    type: 'number',
    description: 'EDI',
    icon: 'mdi-file-document-multiple',
    role: [],
    color: '#CD9575',
    updateFunc: 'getNPEDI',
    tenantID: ['91389', 'master'],
  },
  {
    name: 'NPOtherSLS',
    title: 'Other SLS',
    objectData: 'noData',
    type: 'number',
    description: 'EDI',
    icon: 'mdi-file-document-multiple',
    role: [],
    color: '#CD9575',
    updateFunc: 'getNPEDI',
    tenantID: ['91389', 'master'],
  },
  {
    name: 'NPOther',
    title: 'Other ---',
    objectData: 'noData',
    type: 'number',
    description: 'EDI',
    icon: 'mdi-file-document-multiple',
    role: [],
    color: '#CD9575',
    updateFunc: 'getNPEDI',
    tenantID: ['91389', 'master'],
  },
  {
    name: 'NPDESADV',
    title: 'DESADV',
    objectData: 'noData',
    type: 'number',
    description: 'EDI',
    icon: 'mdi-file-document-multiple',
    role: [],
    color: '#CD9575',
    updateFunc: 'getNPEDI',
    tenantID: ['91389', 'master'],
  },
  {
    name: 'NPOpen',
    title: 'Promese Open',
    objectData: 'noData',
    type: 'number',
    description: 'Promese',
    icon: 'mdi-autorenew',
    role: [],
    color: '#3B3C36',
    updateFunc: 'getNPPicktickets',
    tenantID: ['91389'],
  },
  {
    name: 'NPToPromese',
    title: 'To Promese',
    objectData: 'noData',
    type: 'number',
    description: 'Promese',
    icon: 'mdi-file-document-outline',
    role: [],
    color: '#3B3C36',
    updateFunc: 'getNPPicktickets',
    tenantID: ['91389'],
  },
  {
    name: 'NPAtPromese',
    title: 'At Promese',
    objectData: 'noData',
    type: 'number',
    description: 'Promese',
    icon: 'mdi-file-document-outline',
    role: [],
    color: '#3B3C36',
    updateFunc: 'getNPPicktickets',
    tenantID: ['91389'],
  },
  {
    name: 'NPPicked',
    title: 'Picked',
    objectData: 'noData',
    type: 'number',
    description: 'Promese',
    icon: 'mdi-file-document-outline',
    role: [],
    color: '#3B3C36',
    updateFunc: 'getNPPicktickets',
    tenantID: ['91389'],
  },
  {
    name: 'NPReplen',
    title: 'Replen',
    objectData: 'noData',
    type: 'number',
    description: 'Promese',
    icon: 'mdi-file-document-outline',
    role: [],
    color: '#3B3C36',
    updateFunc: 'getNPPicktickets',
    tenantID: ['91389'],
  },
  {
    name: 'NPsold',
    title: '7d sold',
    objectData: 'noData',
    type: 'number',
    description: 'Docs',
    icon: 'mdi-file-document',
    role: [],
    color: '#CD7F32',
    updateFunc: 'getNPDocs',
    tenantID: ['91389', 'master'],
  },
  {
    name: 'NPshipped',
    title: '7d shipped',
    objectData: 'noData',
    type: 'number',
    description: 'Docs',
    icon: 'mdi-file-document',
    role: [],
    color: '#CD7F32',
    updateFunc: 'getNPDocs',
    tenantID: ['91389', 'master'],
  },
  // {
  //   name: 'AirforceSold',
  //   title: '7d sold',
  //   objectData: 'noData',
  //   type: 'number',
  //   description: 'Docs',
  //   icon: 'mdi-file-document',
  //   role: [],
  //   color: 'indigo',
  //   updateFunc: 'getAirforceDocs',
  //   tenantID: ['91140', 'master'],
  // },
  // {
  //   name: 'AirforceShipped',
  //   title: '7d shipped',
  //   objectData: 'noData',
  //   type: 'number',
  //   description: 'Docs',
  //   icon: 'mdi-file-document',
  //   role: [],
  //   color: 'indigo',
  //   updateFunc: 'getAirforceDocs',
  //   tenantID: ['91140', 'master'],
  // },
  {
    name: 'AirforceWrSyncStatus',
    title: 'WrSync',
    objectData: 'noData',
    type: 'status',
    description: 'Services',
    icon: 'mdi-autorenew',
    role: [],
    color: 'green',
    updateFunc: 'getAirforceWrSync',
    tenantID: ['91140', 'master'],
    url: '',
  },
  {
    name: 'AirforceBijenkorfINV',
    title: 'Bijenkorf INV',
    objectData: 'noData',
    type: 'number',
    description: 'EDI',
    icon: 'mdi-file-document-multiple',
    role: [],
    color: '#CD853F',
    updateFunc: 'getAirforceEDI',
    tenantID: ['91140', 'master'],
  },
  {
    name: 'AirforceBijenkorfSlS',
    title: 'Bijenkorf SLS',
    objectData: 'noData',
    type: 'number',
    description: 'EDI',
    icon: 'mdi-file-document-multiple',
    role: [],
    color: '#CD853F',
    updateFunc: 'getAirforceEDI',
    tenantID: ['91140', 'master'],
  },
  {
    name: 'AirforceBijenkorf',
    title: 'Bijenkorf ---',
    objectData: 'noData',
    type: 'number',
    description: 'EDI',
    icon: 'mdi-file-document-multiple',
    role: [],
    color: '#CD853F',
    updateFunc: 'getAirforceEDI',
    tenantID: ['91140', 'master'],
  },
  {
    name: 'AirforceOpen',
    title: 'Open',
    objectData: 'noData',
    type: 'number',
    description: 'Picktickets',
    icon: 'mdi-file-document-multiple',
    role: [],
    color: consts.dashboardColor,
    updateFunc: 'getAirforcePickTickets',
    tenantID: ['91140', 'master'],
  },
  {
    name: 'AirforceToPickingAPP',
    title: 'To PickingApp',
    objectData: 'noData',
    type: 'number',
    description: 'Picktickets',
    icon: 'mdi-file-document-multiple',
    role: [],
    color: consts.dashboardColor,
    updateFunc: 'getAirforcePickTickets',
    tenantID: ['91140', 'master'],
  },
  {
    name: 'AirforceByPickingAPP',
    title: 'Picked by PickingApp',
    objectData: 'noData',
    type: 'number',
    description: 'Picktickets',
    icon: 'mdi-file-document-multiple',
    role: [],
    color: consts.dashboardColor,
    updateFunc: 'getAirforcePickTickets',
    tenantID: ['91140', 'master'],
  },
  {
    name: 'AirforceResend',
    title: 'Resend',
    objectData: 'noData',
    type: 'number',
    description: 'Picktickets',
    icon: 'mdi-file-document-multiple',
    role: [],
    color: consts.dashboardColor,
    updateFunc: 'getAirforcePickTickets',
    tenantID: ['91140', 'master'],
  },
  {
    name: 'AirforceAPIStatus',
    title: 'Push API Status',
    objectData: 'noData',
    type: 'status',
    description: 'Services',
    icon: 'mdi-autorenew',
    role: [],
    color: 'green',
    updateFunc: 'getAirforcePushAPI',
    tenantID: ['91140', 'master'],
    url: '',
  },
  {
    name: 'AirforceShipToPicking',
    title: 'Shipped to PickingApp',
    objectData: 'noData',
    type: 'number',
    description: 'Picking',
    icon: 'mdi-file-document-multiple',
    role: [],
    color: 'blue',
    updateFunc: 'getAirforcePicking',
    tenantID: ['91140', 'master'],
  },
  {
    name: 'AirforceShipFromPicking',
    title: 'Shipped from PickingApp',
    objectData: 'noData',
    type: 'number',
    description: 'Picking',
    icon: 'mdi-file-document-multiple',
    role: [],
    color: 'blue',
    updateFunc: 'getAirforcePicking',
    tenantID: ['91140', 'master'],
  },
  {
    name: 'AirforceWinkelstraat',
    title: 'Sacktime Winkelstraat',
    objectData: 'noData',
    type: 'status',
    description: 'Datafeed',
    icon: 'mdi-file-document-multiple',
    role: [],
    color: 24,
    updateFunc: 'getAirforceDatafeed',
    tenantID: ['91140', 'master'],
    url: '',
  },
  {
    name: 'AirforceWebshop',
    title: 'Webshop (airforce) B2C',
    objectData: 'noData',
    type: 'status',
    description: 'Datafeed',
    icon: 'mdi-file-document-multiple',
    role: [],
    color: 24,
    updateFunc: 'getAirforceDatafeed',
    tenantID: ['91140', 'master'],
    url: '',
  },
  {
    name: 'AirforceWebshopTest',
    title: 'Webshop (test) B2C',
    objectData: 'noData',
    type: 'status',
    description: 'Datafeed',
    icon: 'mdi-file-document-multiple',
    role: [],
    color: 24,
    updateFunc: 'getAirforceDatafeed',
    tenantID: ['91140', 'master'],
    url: '',
  },
  {
    name: '12345Test',
    title: '12345 test',
    objectData: 'noData',
    type: 'number',
    description: '#abfg',
    icon: 'mdi-file-document-multiple',
    role: [],
    color: 'indigo',
    updateFunc: '',
    tenantID: ['12345'],
  },
  {
    name: '12345Test',
    title: '12345 test',
    objectData: 'noData',
    type: 'number',
    description: '#c47h',
    icon: 'mdi-file-document-multiple',
    role: ['products-admin'],
    color: 'indigo',
    updateFunc: '',
    tenantID: ['12345'],
  },
]

export default sourceData
