import { SkuI } from '@softwear/latestcollectioncore'
import { AuditHeadersI } from '../types/startupDBClient'
import cookies from 'js-cookie'

/**
 * A simple forEach() implementation for Arrays, Objects and NodeLists
 *
 * @see See [GitHub](https://gist.github.com/cferdinandi/42f985de9af4389e7ab3)
 * @private
 * @param {Array|Object|NodeList} collection Collection of items to iterate
 * @param {Function} callback Callback function for each iteration
 * @param {Array|Object|NodeList} scope Object/NodeList/Array that forEach is iterating over (aka `this`)
 */
function forEach(collection, callback, scope = undefined) {
  if (Object.prototype.toString.call(collection) === '[object Object]') {
    for (const prop in collection) {
      if (Object.prototype.hasOwnProperty.call(collection, prop)) {
        callback.call(scope, collection[prop], prop, collection)
      }
    }
    return
  }
  for (let i = 0, len = collection.length; i < len; i++) {
    callback.call(scope, collection[i], i, collection)
  }
}

function padLeft(num, places, char) {
  return String(num).padStart(places, char ? char : '0')
}

/**
 * A function to take a string written in dot notation style, and use it to
 * find a nested object property inside of an object.
 *
 * Useful in a plugin or module that accepts a JSON array of objects, but
 * you want to let the user specify where to find various bits of data
 * inside of each custom object instead of forcing a standardized
 * property list.
 *
 * @param String nested A dot notation style parameter reference (ie "urls.small")
 * @param Object object (optional) The object to search
 *
 * @return the value of the property in question
 */

function getProperty(propertyName, object) {
  const parts = propertyName.split('.')
  const length = parts.length
  let property = object

  for (let i = 0; i < length; i++) {
    if (property) {
      property = property[parts[i]]
    }
  }
  return property
}

function getUniqueValues(a: Array<string>) {
  const t = {}
  return a.filter((e: string) => e && !(t[e] = e in t))
}

/**
 * Pick key value pairs from an object by keys
 * If key is not found it will be ignored
 * @param Object object from which to choose key value pairs
 * @param String[] contains the keys which we want to pick
 *
 * @return Object
 */
function pickByKey(obj, arr) {
  return arr.reduce((acc, record) => (record in obj && (acc[record] = obj[record]), acc), {})
}

function copyToClipboard(el) {
  const body = document.body as any
  let range, sel
  if (document.createRange && window.getSelection) {
    range = document.createRange()
    sel = window.getSelection()
    sel.removeAllRanges()
    try {
      range.selectNodeContents(el)
      sel.addRange(range)
    } catch (e) {
      range.selectNode(el)
      sel.addRange(range)
    }
  } else if (body.createTextRange) {
    range = body.createTextRange()
    range.moveToElementText(el)
    range.select()
  }
  document.execCommand('copy')
}
function apiHeaders(headers?: AuditHeadersI, conentType?: string) {
  if (!conentType) conentType = 'application/json'
  return {
    headers: { ...headers, 'Content-Type': conentType, 'X-Authentication-token': cookies.get('sid') },
  }
}

/**
 *
 * @param url {string} url searchstring like "?parameter=value&anotherParameter=anotherValue"
 * @returns {object} object with parameters like {"parameter":"value","anotherParameter":"anotherValue"}
 */
function parseParams(url: string): any {
  return url
    ?.substring(1)
    .split('&')
    .map((p) => p.split('='))
    .reduce((acc, element) => ({ ...acc, [element[0]]: element[1] }), {})
}

function debounce(fn: Function, delay: number) {
  let timeoutID = null
  return function(this: any, ...args) {
    clearTimeout(timeoutID)
    timeoutID = setTimeout(() => {
      fn.apply(this, args)
    }, delay)
  }
}

function detectBrowser(): string {
  const userAgent = navigator.userAgent.toLowerCase()
  if (userAgent.match(/chrome|chromium|crios/)) return 'chrome'
  if (userAgent.match(/edg/)) return 'edge'
  if (userAgent.match(/firefox|fxios/)) return 'firefox'
  if (userAgent.match(/safari/)) return 'safari' + (navigator?.maxTouchPoints ? '-mobile' : '')
  if (userAgent.match(/opr\//)) return 'opera'
  return 'unknown'
}

function sleep(m) {
  return new Promise((r) => setTimeout(r, m))
}

function sortObjectArrayByStringProperty(o: object[], key: string, direction: boolean) {
  o.sort((a, b) => {
    const sa = a[key] || '',
      sb = b[key] || ''
    if (sa < sb) return direction ? -1 : 1
    if (sa > sb) return direction ? 1 : -1
    return 0
  })
}

function sortObjectArrayByBooleanProperty(o: object[], key: string, direction: boolean) {
  o.sort((a, b) => {
    const ba = a[key] || false,
      bb = b[key] || false
    return direction ? ba - bb : bb - ba
  })
}

function sortObjectArrayByNumberProperty(o: object[], key: string, direction: boolean) {
  o.sort((a, b) => {
    const na = a[key] || 0,
      nb = b[key] || 0
    return direction ? na - nb : nb - na
  })
}

function useCorrectSort(type) {
  switch (type) {
    case 'boolean':
      return sortObjectArrayByBooleanProperty
    case 'currency':
      return sortObjectArrayByNumberProperty
  }
  return sortObjectArrayByStringProperty
}

function skuOneColumnSorting(params = { selectedFields: [], index: null, fields: [] }, previousField, activeSort, rows) {
  const { selectedFields, index, fields } = { ...params }
  const field = fields[selectedFields[index]]

  if (previousField !== selectedFields[index]) {
    field.sort = undefined
  }

  if (previousField === selectedFields[index]) {
    const sort = useCorrectSort(field.type)
    switch (field.sort) {
      case undefined:
        sort(rows, field.field, true)
        if (activeSort) fields[activeSort].sort = null
        activeSort = selectedFields[index]
        field.sort = 'asc'
        break
      case 'asc':
        sort(rows, field.field, false)
        field.sort = 'desc'
        break
      default:
        field.sort = undefined
    }
  }

  return { fields, activeSort, rows }
}

function imageUrlParse(imageUrl, imageWidth) {
  const rootUrl = imageUrl.includes('localhost') ? '' : 'https://images.weserv.nl/?url='

  if (imageWidth == undefined) return `${rootUrl}${imageUrl}`
  return `${rootUrl}${imageUrl}&w=${imageWidth}`
}

function onCookieChange(cookieName, callback) {
  let lastSid = cookies.get(cookieName)
  setInterval(() => {
    const sid = cookies.get(cookieName)
    if (lastSid && sid !== lastSid) callback()
    lastSid = sid
  }, 5000)
}

const getWeek = function(date) {
  // Create a copy of this date object
  const target = new Date(date)

  // ISO week date weeks start on monday
  // so correct the day number
  const dayNr = (date.getDay() + 6) % 7

  // ISO 8601 states that week 1 is the week
  // with the first thursday of that year.
  // Set the target date to the thursday in the target week
  target.setDate(target.getDate() - dayNr + 3)

  // Store the millisecond value of the target date
  const firstThursday = target.valueOf()

  // Set the target to the first thursday of the year
  // First set the target to january first
  target.setMonth(0, 1)
  // Not a thursday? Correct the date to the next thursday
  if (target.getDay() != 4) {
    target.setMonth(0, 1 + ((4 - target.getDay() + 7) % 7))
  }

  // The weeknumber is the number of weeks between the
  // first thursday of the year and the thursday in the target week
  return 1 + Math.ceil((firstThursday - target.valueOf()) / 604800000) // 604800000 = 7 * 24 * 3600 * 1000
}
/**
 * Get the ISO week date year number
 */

const getWeekYear = function(date) {
  // Create a new date object for the thursday of this week
  const target = new Date(date)
  target.setDate(target.getDate() - ((date.getDay() + 6) % 7) + 3)

  return target.getFullYear()
}

const generateTimeframes = function(startDate: Date, endDate: Date, timeframe) {
  const result = []
  let currentDate = new Date(startDate)

  while (currentDate <= endDate) {
    let frameStart, frameEnd

    switch (timeframe) {
      case 'day':
        frameStart = new Date(currentDate)
        frameEnd = new Date(currentDate)
        break
      case 'week':
        frameStart = new Date(currentDate)
        frameStart.setDate(currentDate.getDate() - currentDate.getDay() + (currentDate.getDay() === 0 ? -6 : 1))
        frameEnd = new Date(frameStart)
        frameEnd.setDate(frameStart.getDate() + 6)
        break
      case 'month':
        frameStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
        frameEnd = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
        break
      case 'quarter':
        frameStart = new Date(currentDate.getFullYear(), Math.floor(currentDate.getMonth() / 3) * 3, 1)
        frameEnd = new Date(frameStart.getFullYear(), frameStart.getMonth() + 3, 0)
        break
      case 'year':
        frameStart = new Date(currentDate.getFullYear(), 0, 1)
        frameEnd = new Date(currentDate.getFullYear(), 11, 31)
        break
      default:
        throw new Error('Invalid timeframe')
    }

    result.push([frameStart, frameEnd])

    // Move to the next timeframe
    switch (timeframe) {
      case 'day':
        // currentDate.setDate(currentDate.getDate() + 1)
        currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1)
        break
      case 'week':
        currentDate.setDate(currentDate.getDate() + 7)
        break
      case 'month':
        currentDate.setMonth(currentDate.getMonth() + 1)
        break
      case 'quarter':
        currentDate.setMonth(currentDate.getMonth() + 3)
        break
      case 'year':
        currentDate.setFullYear(currentDate.getFullYear() + 1)
        break
    }
  }

  return result
}
const saveStringAsFile = function(filename, payload, mimeType) {
  const blob = new Blob([payload], { type: mimeType })
  const link = document.createElement('a')

  link.download = filename
  link.href = window.URL.createObjectURL(blob)
  link.dataset.downloadurl = [mimeType, link.download, link.href].join(':')

  const evt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  })

  link.dispatchEvent(evt)
  link.remove()
}

const checkSkuPrices = function(sku: SkuI, store: any, swT: any): any {
  if (!sku) return
  if (sku.buyPrice && sku.buyPrice < sku.price) return
  if (!sku.buyPrice)
    store.dispatch('raiseAlert', {
      header: `${swT('no_buy_price_header')}${sku.id}`,
      body: 'no_buy_price_body',
      type: 'warning',
      timeout: 10000,
    })
  else if (sku.buyPrice >= sku.price)
    store.dispatch('raiseAlert', {
      header: `${swT('wrong_prices_header')}${sku.id}`,
      body: 'wrong_prices_body',
      type: 'warning',
      timeout: 10000,
    })
}

export default {
  apiHeaders,
  copyToClipboard,
  debounce,
  detectBrowser,
  forEach,
  pickByKey,
  padLeft,
  getProperty,
  getUniqueValues,
  parseParams,
  skuOneColumnSorting,
  sleep,
  sortObjectArrayByStringProperty,
  imageUrlParse,
  onCookieChange,
  getWeek,
  getWeekYear,
  generateTimeframes,
  saveStringAsFile,
  checkSkuPrices,
}
